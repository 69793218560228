.mdl-expansion {
  
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  /** Just for demo **/
  width: 700px;
  box-sizing: border-box;
  
  &__summary {
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    display: flex;
    outline: none;
    
    &::-webkit-details-marker {
      display: none;
    }
    
    &::after {
      font-family: 'Material Icons';
      content: '\e313';
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      display: inline-flex;
      flex-direction: column;
      user-select: none;
      justify-content: center;
      transition: transform 200ms, color 200ms;
      margin-left: auto;
      color: rgba(0, 0, 0, .38);
    }
    
    &:focus {
      /* Grey 200 */
      background-color: #EEEEEE;
      
      &::after {
        color: rgba(0, 0, 0, .54);
      }
    }
  }
  
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 500;
    flex-basis: 30%;
    margin-right: 16px;
  }
  
  &__subheader {
    font-size: .75rem;
    color: rgba(0, 0, 0, .54);
  }
  
  &__secondary-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9375rem;
    font-weight: 500;
    flex-basis: 30%;
    color: rgba(0, 0, 0, .87);
    margin-right: 16px;
  }

  &__content {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    
    & + .mdl-expansion__actions {
      border-top: 1px solid rgba(0, 0, 0, .12);
    }
  }
  
  &__actions {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  
  &__action {
    margin-right: 8px;
  }
  
  &[open] {
    .mdl-expansion__summary::after {
        transform: rotate(180deg);
    }
  }
  
}
