@import './main-style.scss';
.stars,
.twinkling,
.clouds {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
.form-wrapper {
	z-index: 88;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 5px;
	padding: 5px;
	max-width: 405px;
	position: fixed;
	top: 50%;
	left: 49.5%;
	transform: translate(-50%, -50%);
	width: 95%;
}
.login-field {
	box-sizing: border-box;
	padding: 5px;
}
.login-field input,
.login-field button {
	width: 100%;
	height: 40px;
	border-radius: 5px;
	font-size: 2em;
	color: #0ac1ff;
	background-color: #0f171e;
	border: none;
	text-align: center;
}
.login-err p {
	padding: 5px;
	font-weight: bold;
	color: $red;
}
.stars { background: #000 url(https://image.ibb.co/mjnygo/stars.png) repeat top center; z-index: 3; }
.twinkling { background: transparent url(https://image.ibb.co/ir1DE8/twinkling.png) repeat top center; z-index: 4; animation: move-twink-back 200s linear infinite; }
.clouds { background: transparent url(https://image.ibb.co/bT4N7T/clouds.png) repeat top center; z-index: 5; animation: move-clouds-back 200s linear infinite; }

@keyframes move-twink-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -10000px 5000px;
	}
}
@keyframes move-clouds-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 10000px 0;
	}
}