// ###########################################################
// Custom Mixin Code for Importing Custom Fonts
// ###########################################################
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}
@mixin font-face_mix($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}
// :After Background Images Setup
	@mixin afterBG(){
		background-position: center;
		background-repeat: repeat;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		display: block;
		position: absolute;
	}

// ###########################################################
// Common Mixin
// ###########################################################
	@mixin customWidthHeight($width, $height, $visible: true, $position: false){
		
		@if $position {
			position: $position;
		}
		
		@if $visible == false {
			visibility: hidden;
		}
		width: $width;
		height: $height;
	}

// ###########################################################
// Overlay Mixin
// ###########################################################
	@mixin overlay_mix($background: rgba(12,23,30,.93)){
		/* background-color: $background; */
		background-color: $background;
		position: absolute;
		z-index: 999;
	}
	@mixin sm_overlay_mix($background: rgba(25,26,28,1)){
        background-color: $background;
        width: 100%;
        padding: 20px;
        position: fixed;
        bottom: 0;
        z-index: 2;
	}
	@mixin sm_overlay-after_mix(){
		background-image: uel('./../Images/noise.png');
		background-position: center;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		opacity: 0.4;
	}
