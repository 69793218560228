// Font Imports
@import './mixins';
@import './toggleswitches_mixins';

@include font-face_mix('Blizzard', './../Fonts/blizzard.regular', null, null, 'woff');
@include font-face_mix('BlizzardLight', './../Fonts/blizzard.light', null, null, 'woff');
@include font-face_mix('BlizzardBold', './../Fonts/blizzard.bold', null, null, 'woff');
@include font-face_mix('OpenSans', './../Fonts/open-sans.regular', null, null, 'woff');
@include font-face_mix('OpenSans', './../Fonts/open-sans.light', null, null, 'woff');
@include font-face_mix('OpenSans', './../Fonts/open-sans.bold', null, null, 'woff');
@include font-face_mix('AmazonEmber', './../Fonts/AmazonEmber_Rg', null, null, 'woff');
@include font-face_mix('AmazonEmberLight', './../Fonts/AmazonEmber_Lt', null, null, 'woff');

// Common Variables
//       Overall Layout Stuff
$contentMaxWidth: 1510px;

//       Commonly used Stuff
$centerMargin: 0 auto;
$borderRadius: 6px;
$commonSpacing: 0.5rem;

//       Color Stuff
$standardFontSize: 12px;
$mainTextColor: #0ac1ff;
$grey: #8197a4;
$orange: #e08a09;
$gold: #dddd0b;
$red: #dd270b;
$green: #a9dd0b;
$white: hsla(0, 0%, 95%, 0.9);
$whiteHover: white;
$transdark: #070b0f;
$darkBackground: #0f171e;
$darkRed: #3e2323;
$mediumBackground: #1b2530;
$lightBackground: #232f3e;
$xtraLightBackground: #252e39;

// ###########################################################
/* Text Color Setup */
// ###########################################################
.text-white {
	color: $white;
}
// ###########################################################
/* Body/HTML Setup */
// ###########################################################
html {
	cursor: default;
	box-sizing: border-box;
	line-height: 24px;
}
body {
	margin: 0 !important;
	background-color: $darkBackground;
}
body,
html {
	font-size: $standardFontSize;
}
// ###########################################################
// Containers Setup
// ###########################################################
.container-main {
	font-family: AmazonEmber !important;
	color: $mainTextColor;
	background: $darkBackground;
}
.container-content {
	/*
	max-width: $contentMaxWidth;
	margin: $centerMargin 125px;
	*/
}
// ###########################################################
// Layout Setup
// ###########################################################
.text-center {
	text-align: center;
}
.row {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.responsive {
	width: 100%;
	height: auto;
}
.grid {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, 316px);
	//grid-template-rows: repeat(auto-fit, 217px);
}
.grid2 {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: [col] 50% [col] 50%;
	grid-template-rows: [row] auto [row] auto [row];
}
.box {
	padding: 0.5rem;
}

.col-12 {
	width: 100%;
}
.col-11 {
	width: 91.66%;
}
.col-10 {
	width: 83.33%;
}
.col-9 {
	width: 75%;
}
.col-8 {
	width: 66.66%;
}
.col-7 {
	width: 58.33%;
}
.col-6 {
	width: 50%;
}
.col-5 {
	width: 41.67%;
}
.col-4 {
	width: 33%;
}
.col-3 {
	width: 25%;
}
.col-2 {
	width: 16.67%;
}
.col-1 {
	width: 8.333%;
}
.col-05 {
	width: 8.333%;
}
.clearfix:after{
	content: "";
	clear: both;
}
// ###########################################################
// Rotten Tomatoes Styles
// ###########################################################
.icon {
	background: url(./../Images/RottenTomatoes/icons-v4.png);
	vertical-align: text-bottom;
}
.icon.small {
	height: 24px;
	width: 24px;
	max-width: 24px;
	min-width: 24px;
	margin-right: 5px;
}
.icon.small.fresh {
	background: url(/static/media/icons-v4.54da261b.png) 0 0;
	background-size: 8.2rem;
}
.icon.small.rotten {
	background: url(/static/media/icons-v4.54da261b.png) -24px 0px;
	background-size: 8.2rem;
}
.icon.small.popcorn-upright {
	background: url(/static/media/icons-v4.54da261b.png) -47px -23px;
	background-size: 8.2rem;
}
.icon.small.popcorn-rotten {
	background: url(/static/media/icons-v4.54da261b.png) -1px -23px;
	background-size: 8.2rem;
}
.icon.small.certified {
	background: url(/static/media/icons-v4.54da261b.png) -47px 0px;
	background-size: 8.2rem;
}
.info-rotten-reviews {
	color: #eee;
	font-family: AmazonEmber;
	flex-direction: row;
	padding: 4rem;
	margin: 2rem;
	border-radius: 2rem 0rem 0rem 2rem;
	background: rgba(46, 57, 63, 0.3);
	border: 1px solid $grey;

	max-height: 30rem;
	overflow: scroll;
	padding: 1rem;
}
.rotten-review-item {
	position: relative;
	display: flex;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 1rem;
}
.rotten-review-reviewer {
	background-repeat: no-repeat;
	background-color: rgba(0, 0, 0, 0.6);
	background-size: 72px 72px;
	border-radius: 1rem;
	padding: 16.5px 0px 0px 6rem;
	margin: 1rem;
	min-height: 56px;
	min-width: 10.9rem;
	max-width: 10.9rem;
}
.rotten-review-reviewer img {
	max-width: 72px;
	min-width: 72px;
	max-height: 72px;
	max-height: 72px;
}
.rotten-review-content {
	padding: 2.2rem 21px 0px 0.1px;
	border-radius: 1rem;
	/* padding: 1rem; */
	width: 100%;
	position: relative;
	display: flex;
}
a.full-review {
	position: absolute;
	bottom: 0.7rem;
	right: 0.7rem;
}
.review-container {
	margin: 1rem;
}
.casting-actors,
.casting-creators,
.casting-directors,
.casting-producers,
.casting-screenwriters,
.info-rotten-trailer {
	color: #eee;
	font-family: AmazonEmber;
	flex-direction: row;
	padding: 4rem;
	margin: 2rem;
	border-radius: 2rem 0rem 0rem 2rem;
	background: rgba(46, 57, 63, 0.3);
	border: 1px solid #8197a4;
	max-height: 30rem;
	overflow: scroll;
	padding: 1rem;
}
.cast-container {
	width: 10rem;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.3);
	margin: 1rem;
	padding: 1rem;
	border-radius: 1rem;
}
.cast-container img {
	border-radius: 1rem;
}
.cast {
	display: grid;
	grid-template-columns: repeat(auto-fit, 168px);
	grid-column-gap: 1rem;
}
.rt-ratings-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, 18px);
	grid-column-gap: 1rem;
}

.trailer-iframe {
	height: 25rem;
}
.trailer-iframe iframe {
	width: 100%;
	height: 100%;
	border: none;
}

// ###########################################################
// TV Styles
// ###########################################################
.tv-ep{
	list-style: none;
	color: white;
	text-shadow: -2px -1px 1px rgba(0, 0, 0, 0.8);
	display: flex;
	//margin: 1rem;
	flex: 1 1 auto;
}
.tv-ep-episode-selector{
	height: 30rem;
	overflow: auto;
}
.tv-ep-list{
	padding-inline-start: 0;
}
.tv-ep-list-season{
	width: 10%;
	margin-left: 1rem;
}
.tv-ep-list-num{
	width: 10%;	
}
.tv-ep-list-res{
	width: 10%;
}
.tv-ep-list-name{
	width: 50%;
	text-align: left;
}
.tv-ep-list-summary{
	text-align: left;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.tv-ep-list-count{
	text-align: center;
	width: 10%;	
}
.tv-ep-list-titles{
	display: flex;
	flex: 1;
	text-align: center;
}
.tv-ep-list-actions{
	width: 17%;
	display: flex;
	justify-content: space-between;
	padding-left: 1rem;
	text-align: center;
}
.tv-ep-list-actions-itm {
    width: 20%;
    display: flex;
    letter-spacing: 1rem;
}
.react-player-naked{
	width: 100% !important;
	margin: 0 auto;
}
.player-naked{
	margin: 1rem 0 0 0;
	text-align: left;
}
.player-download{
	text-align: center;
	width: 100%;
	margin-top: 5px;
}
.tv-ep-player{
	width: 100%;
	height: 447px;
}
.tv-aria-list{
	position: relative;
	top: -3px;
}
.tv-ep-season-selector{
	text-align: left;
	margin-left: 3rem;
	margin: 8px 0 1rem 2.7rem;
	display: flex;
}
.tv-ep-season-selector select{
	height: 100%;
	white-space: nowrap;
	overflow: auto;
	padding: 1rem 1rem 1rem 1rem;
	text-align: left;
	font-size: 1.5rem;
	float: left;
	width: 100%;
}
.tv-season-num,.tv-season-quality,.tv-season-download{
	width: 33.3%
}
.tv-season-quality select{
	margin-left: 1.2rem;
}
.tv-season-download{
	text-align: center;
}
.tv-season-download button{
    font-size: 1rem;
	padding: 0.7rem;
	margin-top: 1rem;
}
.tv-show-details{
	color: white;
	width: 86.3%;
	margin: 0 0 3rem 3rem;
	position: absolute;
	bottom: 0;
	text-shadow: -2px -1px 1px rgba(0, 0, 0, 0.8);
	background-color: rgba(20,20,20,.5);
	padding: 20px;
	border-radius: 10px;
}
.tv-show-name{
	font-size: 3rem;
	margin-bottom: 3rem;
	line-height: normal;
}
.tv-show-format{
	margin-bottom: 1rem;
}
.tv-show-summary{
	margin-bottom: 1rem;
}
.tv-ep-poster{
	height: 100%;
	width: 50%;
	box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, .8);
	position: absolute;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	float: left;
}
.tv-ep-details{
	height: 99%;
	width: 50%;
	float: right;
	text-align: center;
	//overflow: scroll;
}

.tv-results-container {
	width: 89.5%;
	margin: 1rem auto;
	border-radius: 1rem;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 1rem;	
}
.tv-poster-container {
	width: 100%;
}
.tv-result-itm {
	grid-column: col 1 / span 4;
	grid-row: row 2;
	width: 98.9%;
	border-radius: 1rem;
	background-color: rgba(0, 0, 0, 0.3);
	margin-bottom: 1rem;
}
.tv-results-container,
.tv-season-title,
.tv-season-eps {
	grid-column: col / span 4;
	grid-row: row 3;
}
.tv-poster {
	grid-column: col;
	grid-row: row;
}
.tv-poster-img {
	max-width: 40%;
}
.tv-summary {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 1rem;
	padding: 1rem;
	grid-column: col 2;
	grid-row: row;
	max-width: 93%;
}
.tv-poster-title {
	font-size: 1.5rem;
	padding: 1rem 0;
}
.tv-season-eps {
	text-decoration: none;
	list-style: none;
	padding: 0rem 1.5rem;
}
.tv-season-eps li {
	padding: 1rem;
}
.tv-season-title {
	padding-left: 1.4rem;
}
.tv-list {
	/* No Set Width
	Also remove max-width and margin from .container-content
	width: 88.7%
	grid-template-columns: repeat(auto-fit, 153px);
	*/
	width: 91.7%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 173px);
    grid-template-rows: repeat(auto-fit, 286px);
}
.tv-ep-itm{
	border: 1px solid #212529;
    border-radius: 6px;
    max-width: 151px;
    max-height: 262px;
    padding: 2px 10px 10px 10px;
    justify-content: center;
    text-align: center;
    margin: 0.5rem;
}

.tv-ep-itm:hover {
	background-color: #141414;
}
.tv-ep-itm img {
	//width: 100%;
	//height: 178px;
	//height: 221px;
	//margin: 1rem auto auto auto;
    min-width: 127px;
    max-width: 127px;
    min-height: 189px;
    max-height: 189px;
    width: 99%;
    height: 100%;
    margin-bottom: 0.5rem;
    border: 1px solid #fff;
    cursor: pointer;
}
.tv-ep-num {
	position: absolute;
	bottom: 88%;
	left: 4.4%;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 3px;
	border-radius: 6px;
	font-size: 0.8rem;
	line-height: 1;
}
.tv-ep-img-count {
	position: absolute;
	bottom: 88%;
	right: 4.4%;
	background-color: rgba(0, 0, 0, 0.8);
	padding: 3px;
	border-radius: 6px;
	font-size: 0.8rem;
	line-height: 1;
}
.tv-season-selector {
	border: 1px solid $mainTextColor;
	line-height: 2;
	padding: 1rem;
	min-width: 15rem;
	background-color: rgba(0, 0, 0, 0.3);
	color: $white;
}
.optSeason {
	background: $transdark !important;
	font-size: 1.5rem;
}
.tv-ep-itm-details {
	font-size: 0.8em;
	font-family: BlizzardBold;
	line-height: 1.5;
	color: $mainTextColor;
	text-align: center;
}
.tv-ep-overlay{
	@include overlay_mix($gold);
	@include customWidthHeight(100%, 100%, true, false);
}
// ###########################################################
// Settings Styles
// ###########################################################
.settings-container,
.settings-save-container {
	display: flex;
	width: 50%;
	margin: 0 auto;
}
.settings-container {
	background-color: rgba(0, 0, 0, 0.3);
	padding: 1rem 1rem 2rem 1rem;
	border-radius: 1rem;
	min-width: 410px;
}
.settings-group-title {
	font-size: 1.3rem;
	text-decoration: underline;
	color: white;
	height: 3rem;
}
.settings-save-container {
	text-align: right;
}
.settings-titles,
.settings-values input {
	line-height: 2;
	font-size: 1rem;
}
.settings-values input {
	width: 100%;
	margin: 4px;
	padding: 5px;
}
.settings-titles {
	margin: 10px 5px 5px 5px;
	padding: 5px;
	border-right: 1px dotted #0ac1ff;
}
.row.settings-itm {
	height: 42px;
}
.settings-values {
	padding: 5px 5px 5px 20px;
}
.col-12.settings-submit button {
	line-height: 2;
	margin: 8px;
}
// ###########################################################
// Webkit Overrides
// (Scrollbars)
// ###########################################################
::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-thumb {
	background: $mainTextColor;
}
// ###########################################################
// Header Setup
// ###########################################################
.header-color {
	background-color: $lightBackground !important;
	color: white !important;
	border: 1px solid black !important;
	margin-bottom: 10px !important;
}
// ###########################################################
// Navigation Setup
// ###########################################################
.top-link {
	color: $white !important;
}
.top-link:hover {
	color: $whiteHover !important;
}
.side-link {
	color: $mainTextColor !important;
}
.side-link:hover {
	background-color: $mainTextColor !important;
	color: $whiteHover !important;
}
// ###########################################################
// Content Setup
// ###########################################################
.all-content::after {
	@include afterBG();
	//background-image: url(./../Images/noise.png);
	background-size: initial;
	z-index: -1;
	opacity: 1;
}

// ###########################################################
// Button Hover Setup
// ###########################################################
.blue_hover,
.orange_hover,
.green_hover,
.gold_hover {
	color: $grey;
	cursor: pointer;
}
.red_hover {
	color: white;
}
// Hover Colors
.blue_hover:hover {
	color: $mainTextColor;
}
.orange_hover:hover {
	color: $orange;
}
.green_hover:hover {
	color: $green;
}
.gold_hover:hover {
	color: $gold;
}
.red_hover:hover {
	color: $red;
}

// ###########################################################
// Results Setup
// ###########################################################
.results-container {
	display: flex;
	width: 91.1%;
	margin: 0 auto;
	margin-bottom: 6px;
}
.results-count {
	font-size: 21px;
	line-height: 24px;
	color: $white;
	text-align: right;
	width: 50%;
	display: flex;
	align-self: flex-end;
}

// ###########################################################
// Overlay Setup
// ###########################################################
// Main Overlay Setup
.overlay {
	@include overlay_mix($darkBackground);
	@include customWidthHeight(100%, 100%, true, false);
	display: none;
	/*
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        */
}
.overlay-show {
	display: block !important;
}
.overlay::before {
	@include afterBG();
	/* background-image: url(./../Images/preset-dark.png); */
	z-index: -1;
	opacity: 0.4;
}
.overlay::after {
	@include afterBG();
	background-image: url(./../Images/noise.png);
	z-index: -1;
	opacity: 0.4;
}
// Small Overlay Setup
.sm-overlay {
	@include sm-overlay_mix($lightBackground);
	color: $grey;
}
.sm-overlay::after {
	@include sm_overlay-after_mix();
}
// Hover Title Setup
.hover-title {
	width: 100%;
	z-index: 2;
}
.quick-info-container {
	color: $white;
	display: block;
	width: 100%;
	z-index: 2;
}
.quick-info-close {
	font-size: 1.5em;
	width: 20px;
	position: sticky;
	left: 93%;
	z-index: 4;
}
.close-quick-info:hover {
	color: $red !important;
}
.quick-info-header {
	color: $mainTextColor;
	font-weight: 600;
}
.quick-info-header,
.quick-info-subheader {
	text-align: center;
}
.quick-info-col {
	width: 328px;
	display: flex;
	margin: $centerMargin;
}
.quick-info-row {
	display: flex;
	padding: 1px 0px 0px 19px;
	width: 50%;
}
.quick-info-title {
	font-weight: 600;
	padding-right: 6px;
}
// ###########################################################
// Context Menu Setup
// ###########################################################
$plexwebContextBG: rgba(0, 0, 0, 0.8);
$plexwebContextHoverBG: #5087b3;
$plexwebContextSeperaterColor: #000;

.plexwebContextMenu {
	display: none;
	visibility: hidden;
	max-width: 193px;
	position: absolute;
}
.plexwebContextMenu ul {
	list-style: none;
	border-radius: 5px;
	background: $plexwebContextBG;
	margin: 0;
	padding: 0;
}
.plexwebContextMenu li {
	border-radius: 5px;
	padding: 4px 0px 4px 0px;
	margin: 0;
}
.plexwebContextMenu li:hover {
	z-index: 99;
}
button + .plexwebContextMenu:active,
button:focus + .plexwebContextMenu {
	visibility: visible;
}
.plexwebContextMenu ul > li:first-child button {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.plexwebContextMenu ul > li:last-child button {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
.plexwebContextMenu button {
	width: 100%;
	padding: 8px 20px 8px 20px;
	font-family: Arial, Verdana, sans-serif;
	font-size: 1.161rem;
	line-height: 1.15;
	margin: 0;
	overflow: visible;
	border: 0;
	text-align: left;
	outline: none;
	cursor: pointer;
	background: $plexwebContextBG;
	color: white;
}
.plexwebContextMenu button:hover {
	background: $plexwebContextHoverBG;
	color: #fff;
	box-shadow: 0 0 2px 2px #fff,
		// Inner Glow
			0 0 3px 3px #0047ff,
		// Middle Glow
			0 0 4px 4px #bf3454; // Outer Glow
	z-index: 99;
}
.plexwebContextMenu .context-seperator {
	background-color: $plexwebContextSeperaterColor;
	height: 2px;
}
// ###########################################################
// Video Info Window
// ###########################################################
.text-content {
	max-width: 1200px;
	margin: 0 auto;
}
.info-container {
	color: #eee;
	font-family: AmazonEmber;
	display: flex;
	flex-direction: row;
	padding: 4rem;
	margin: 2rem;
	border-radius: 2rem;
	/* background: rgba(0,0,0,.3); */
	background-color: rgba(4, 7, 10, 1);
	border: 1px solid $grey;
}

.info-instructions {
	text-align: center;
	max-width: 1200px;
	margin: 2rem;
	padding: 0rem 1.5rem;
}
.info-instructions i {
	float: right;
	font-size: 2rem;
	color: #0ac1ff;
	cursor: pointer;
}
.info-instructions i:hover {
	color: #ff0a33;
}
.info-poster {
	margin-right: 2rem;
	/*border-right: 2px solid rgba(0,0,0,.3); */
	border-right: 2px solid $grey;
	padding-right: 2rem;
}
.info-poster img {
	border: 1px solid $grey;
}
.info-details-title {
	font-size: 1.6rem;
}
.info-details-year,
.info-details-runtime,
.info-details-rating,
.info-details-video,
.info-details-audio,
.info-details-summary,
.info-details-rating p {
	font-size: 0.8rem;
}
.info-details-dblcontainer {
	display: flex;
	flex-direction: row;
}
.info-details-rating {
	margin-left: 20px;
}
.info-details-rating .spanRating {
	padding: 4px;
	border-radius: 5px;
	background-color: rgba(94, 222, 249, 0.15);
	color: #fff;
	text-transform: uppercase;
}
.info-seperator {
	margin: 10px 0;
	height: 2px;
	/*background-color: rgba(0,0,0,.3);*/
	background-color: $grey;
}
.spanTitle {
	color: hsla(0, 0%, 100%, 0.45);
	text-transform: uppercase;
	margin-right: 10px;
}
.sources-container {
	max-height: 30rem;
	overflow: scroll;
	color: #eee;
	font-family: AmazonEmber;
	padding: 1rem;
	margin: 2rem;
	border-radius: 2rem 0rem 0rem 2rem;
	background: rgba(0, 0, 0, 0.3);
	border: 1px solid $grey;
}
.sourceTitle {
	font-weight: bold;
}
.sourceRow {
	width: 100%;
	display: flex;
}
.sourceRow > * {
	display: inline;
	font-size: 0.7rem;
	color: #eee;
	padding: 0.1rem;
	text-align: center;
}
.sourceActions i {
	color: #0ac1ff;
	cursor: pointer;
}
.sourceActions i:hover {
	color: #0aff5c;
}
// ###########################################################
// Spinner / Loading
// ###########################################################
.loader {
	color: #ffffff;
	font-size: 90px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
			-0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
			-0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
			-0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
			-0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
			-0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
			-0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
			0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
// ###########################################################
// Movie Poster Setup
// ###########################################################
.vid-list-msg {
	width: 50%;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 2rem;
	border-radius: 2rem;
	border: 0.2rem solid;
}
.vid-list-msg img {
	width: 9rem;
}
.aria-link {
	text-decoration: none;
}
.video-list-container {
	width: 100%;
}
.video-list {
	width: 91.7%;
	margin: $centerMargin;
	display: grid;
	grid-template-columns: repeat(auto-fit, 173px);
	grid-template-rows: repeat(auto-fit, 286px);
}
.vid-item {
	border: 1px solid #212529;
	border-radius: $borderRadius;
	max-width: 151px;
	max-height: 262px;
	padding: 2px 10px 10px 10px;
	justify-content: center;
	text-align: center;
	margin: $commonSpacing;
}
.rc {
	width: 100%;
	display: flex;
}
.vid-header {
	display: flex;
}
.vid-geo {
	width: 85%;
	padding-left: 6px;
	text-align: left;
}
.vid-filter-res-hide,
.vid-filter-title-hide,
.vid-filter-year-hide {
	display: none;
}
.vid-geo span {
	padding: 4px;
	border-radius: 5px;
	background-color: rgba(94, 222, 249, 0.15);
	color: #fff;
	text-transform: uppercase;
	font-size: 0.6rem;
}
.vid-report {
	color: #292f35;
	width: 15%;
	margin-bottom: 0.2em;
	padding-right: 5px;
	text-align: right;
}
.vid-report:hover {
	color: $red;
}
.vid-actions {
	margin-top: $commonSpacing;
	color: $white;
	margin-bottom: $commonSpacing;
	display: flex;
	justify-content: space-between;
}
.vid-img {
	background-color: #505a64;
	@include customWidthHeight(127px, 189px, true, false);
	margin: 0px auto 12px auto;
	text-align: center;
}
.vid_poster_img {
	width: 100%;
	height: 100%;
	min-width: 127px;
	max-width: 127px;
	min-height: 189px;
	max-height: 189px;
	@include customWidthHeight(99%, 100%, true, false);
	margin-bottom: $commonSpacing;
	border: 1px solid #fff;
	cursor: pointer;
}
.vid-img img::after {
	background-image: url(./../Images/new-item.png);
	background-repeat: no-repeat;
	width: 59px;
}
.vid-title {
	font-size: 0.8em;
	font-family: BlizzardBold;
	line-height: 1.5;
	color: $mainTextColor;
}
.ellipsis {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
}
.vid-info-container {
	width: 100%;
	margin: $centerMargin;
}
.vid-info-overlay {
	@include customWidthHeight(100%, 100%, false, 'fixed');
	background-color: rgba(0, 0, 0, 0.5);
	left: 0;
	top: 66px;
	display: flex;
}
.vid-info {
	@include customWidthHeight(50%, auto, true, 'relative');
	background-color: #3b505b;
	color: white;
	max-width: 631px;
	max-height: 75%;
	margin: $centerMargin;
	display: block;
	overflow: auto;
	overflow-x: hidden;
	border-radius: $borderRadius;
	border: 1px solid #172126;
	top: 10%;
}
.vid-info-itemContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.vid-info-header,
.vid-info-close {
	background-color: #2c3c44;
}
.vid-info-header {
	font-weight: 600;
	font-size: 1.2em;
	padding: 0 1.5em 1.5em 1.5em !important;
	text-align: center;
	border-bottom: 2px dotted black;
}
.vid-info-item {
	display: flex;
	padding: 1em;
	border-bottom: 1px solid black;
	flex-direction: row;
	flex-wrap: wrap;
}
.vid-info-title {
	font-weight: 600;
	min-width: max-content;
	color: $mainTextColor;
}
.vid-info-title,
.vid-info-content,
.vid-info-header,
.player-title {
	padding: $commonSpacing;
	text-shadow: 1px 1px 2px #000;
}
.vid-info-close {
	text-align: right;
	padding: 12px 12px 6px 0;
	font-size: 1.5em;
}
.vid-info-close i:hover {
	color: $red;
}
.vid-extras {
	font-family: Blizzard;
	display: flex;
	flex-direction: row;
	font-size: 11px;
	color: $grey;
}
.vid-extras-content-left {
	width: 50%;
	text-align: left;
	padding-left: 2px;
}
.vid-extras-content-left,
.vid-extras-content-right {
	width: 50%;
}
.vid-extras-content-right {
	text-align: right;
}
.vid-year {
	font-size: 11px;
	width: 50%;
	text-align: left;
	padding-left: 2px;
}
.vid-count,
.vid-year {
	color: $white;
}
.vid-img-new {
	position: absolute;
	left: 0;
	top: 0;
	width: 59px;
	height: 34px;
	background-image: url(./../Images/new-item.png);
	background-repeat: no-repeat;
}
// ###########################################################
// Video Player Setup
// ###########################################################
.closeVideoPlayer {
	text-align: right;
	font-size: 2em;
}
.player-responsive {
	position: relative;
	padding-top: 56.25%;
}
.react-player {
	background-color: black;
	margin: $centerMargin;
	@include customWidthHeight(100% !important, 100% !important, true, false);
}
.player-container {
	width: 640px;
	margin: $centerMargin;
	margin-top: 2%;
	background-color: #696969;
	padding: 2px;
	box-shadow: 2px 2px 5px #2b2a2a;
}
.player-title {
	color: $white;
	background-color: #3b505b;
	font-size: 1em;
	text-align: center;
	margin-top: $commonSpacing;
	border-radius: $borderRadius;
}
.player-title h4 {
	margin-top: 0;
}
.myPlayer {
	width: 640px;
}
// ###########################################################
// Filter Setup
// ###########################################################
.filter-options {
	font-family: AmazonEmber;
}
.filter-options h3 {
	font-size: 2em;
	font-weight: 500;
	border-bottom: 2px dotted $darkBackground;
}
.results-filter {
	text-align: right;
	width: 50%;
}
.results-filter svg {
	font-size: 12px;
	width: 2em;
	fill: $white;
}
.results-filter svg:hover {
	fill: $mainTextColor;
}
.filter {
	padding: 5px 5px 5px 0px;
}
.filterLabel {
	font-size: 15px;
	display: table-cell;
}
.filterLabel-input {
	font-size: 15px;
	font-family: BlizzardLight;
	height: 32px;
	line-height: 32px;
}
.filter.filterInput {
	width: 85%;
	display: contents;
}
.filterInput input {
	width: 100%;
	padding: $commonSpacing;
	border-radius: $commonSpacing;
	color: rgba(242, 242, 242, 0.9);
	background-color: #0f171e;
	font-size: 15px;
	font-family: AmazonEmberLight;
	overscroll-behavior: contain;
	text-overflow: ellipsis;
}
// ###########################################################
// Custom Slider Switches Setup
// ###########################################################

// ###########################################################
// Tooltip Setup
// ###########################################################
.tooltip {
	display: inline-block;
	position: relative;
	text-align: left;
}
// Right Tooltips
.tooltip .right img {
	width: 400px;
	max-height: 98px;
	border-radius: 8px 8px 0 0;
}
.tooltip .right {
	min-width: 200px;
	max-width: 400px;
	top: 50%;
	left: 100%;
	margin-left: 20px;
	transform: translate(0, -50%);
	//padding: 10px 20px;
	padding: 0;
	color: #eeeeee;
	background-color: #444444;
	font-weight: normal;
	font-size: 13px;
	border-radius: 8px;
	position: absolute;
	z-index: 99999999;
	box-sizing: border-box;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.8s;
}

.tooltip:hover .right {
	visibility: visible;
	opacity: 1;
}

.tooltip .right i {
	position: absolute;
	top: 50%;
	right: 100%;
	margin-top: -12px;
	width: 12px;
	height: 24px;
	overflow: hidden;
}

.tooltip .right i::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	left: 0;
	top: 50%;
	transform: translate(50%, -50%) rotate(-45deg);
	background-color: #eeeeee;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
// Bottom Tooltips
.tooltip .bottom {
	min-width: 400px;
	top: 40px;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 7px 10px;
	color: #444444;
	background-color: #eeeeee;
	font-weight: normal;
	font-size: 13px;
	border-radius: 8px;
	position: absolute;
	z-index: 99999999;
	box-sizing: border-box;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.8s;
}

.tooltip-show-bottom {
	visibility: visible !important;
	opacity: 1 !important;
}

.tooltip .bottom i {
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -12px;
	width: 24px;
	height: 12px;
	overflow: hidden;
}

.tooltip .bottom i::after {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	left: 50%;
	transform: translate(-50%, 50%) rotate(45deg);
	background-color: #eeeeee;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
// ###########################################################
// Searchbar Setup
// ###########################################################
.searchbar-container {
	width: 91.1%;
	margin: 0 auto;
	padding: 5px;
}
.searchbar-container {
	width: 92.1%;
	margin: 0 auto;
	padding: 5px 11px 5px 5px;
	box-sizing: border-box;
}
input.searchbar {
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: none;
	font-size: 2em;
	font-family: AmazonEmberLight, sans-serif;
	color: $mainTextColor;
	background-color: $lightBackground;
	text-align: center;
}
.searchbar-nsfw {
	background-color: $darkRed !important;
}
